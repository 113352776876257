<script setup>
    import { computed } from "vue"

    const emit = defineEmits(["update:checked"])

    const props = defineProps({
        checked: {
            type: [Array, Boolean],
            default: false,
        },
        value: {
            type: [String, Number],
            default: null,
        },
    })

    const proxyChecked = computed({
        get() {
            return props.checked
        },

        set(val) {
            emit("update:checked", val)
        },
    })
</script>

<template>
    <input
        v-model="proxyChecked"
        type="checkbox"
        :value="value"
        class="rounded border-gray-300 text-brand-primary shadow-sm focus:ring-brand-primary dark:border-gray-700 dark:bg-gray-900 dark:focus:ring-offset-gray-800"
    />
</template>
